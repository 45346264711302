import React, {useState} from 'react';
import {useSelector} from 'react-redux';

import {IStore} from 'store/store';
import {INominal} from 'store/components/PrizesSlice';
import {GetIcon} from 'store/enums/normalizedIcons';

import Counter from 'components/counter/Counter';

interface INominalTableItem {
  item: [string, INominal];
  visibleError: any;
  clear: any;
  markup: string;
}

const NominalTableItem = ({item, visibleError, markup, clear}: INominalTableItem) => {
  const colors = useSelector((state: IStore) => state.settings.colors);
  const domain = useSelector((state: IStore) => state.settings.currentDomainName);

  const [selected, setSelected] = useState(false);

  return (
    <div
      key={item[0]}
      className={`
        nominal-table__info-container 
        ${selected ? 'nominal-table__info-container_selected' : ''}
      `}>
      <div
        className={`
          nominal-table__nominal 
          ${!item[1].qty ? 'nominal-table__nominal_color-blue' : ''}
        `}>
        {item[1].nominal} <span className={'nominal-table__nominal_color-grey'}>₽</span>
      </div>
      {markup ? (
        <div
          className={`
          nominal-table__markup 
          ${!item[1].count ? 'nominal-table__nominal_color-blue' : ''}
        `}>
          {Math.floor(+item[1].nominal / 100 * parseInt(markup))} <span className={'nominal-table__nominal_color-grey'}>₽</span>
        </div>
      ) : (<div className={'nominal-table__markup'}></div>)}

      <div className={'nominal-table__qty'}>
        {item[1].qty}
      </div>
      <div className={'nominal-table__nominal-with-qty'}>
        <div className={'nominal-table__nominal-with-qty-cell'}>
          <div
            className={`
              nominal-table__nominal 
              nominal-table__nominal_mobile-show 
              ${item[1].qty ? 'nominal-table__nominal_color-blue' : ''}
            `}>
            {item[1].nominal} <span className={'nominal-table__nominal_color-grey'}>₽</span>
          </div>
          &nbsp;{'/'}&nbsp;
          <div className={'nominal-table__qty nominal-table__qty_mobile-show'}>
            {item[1].qty}
          </div>
        </div>
      </div>
      <div className={'nominal-table__counter'}>
        <Counter setSelected={setSelected} error={visibleError} item={item} modeCounter={'orderCard'} />
      </div>
      <div className={'nominal-table__cross'} onClick={() => clear(item[0])}>
        {
          item[1].count > 0
            ? (
              <GetIcon
                iconName={'Cross'}
                fill={colors[domain].crossIcon.mainIcon}
                secondaryFill={colors[domain].crossIcon.secondIcon}
                opacity={colors[domain].crossIcon.opacity}
              />
            ) : <div style={{width: 17}} />
        }
      </div>
    </div>
  );
};

export default NominalTableItem;
