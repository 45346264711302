import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {transactionsData} from '../enums/mockData';

import ax from 'utils/ax';
import {data} from 'autoprefixer';

export interface IGetPayment {
  purse_id: number,
  sum: number,
  commission?: number,
  ndfl: number,
  type: string,
  phone?: string,
  card?: string,
  bank_id?: number,
}

export interface IGetRecipientDataSbp {
  phone: string,
  bank_id: string,
}

export interface IPaymentHistoryItem {
  id: string,
  payment_type: 'phone' | 'card',
  amount: number,
  tax?: number,
  commission: number,
  status: number,
  recipient_number: string,
  created_at: string,
  status_label: string,
}

export interface IGetBankListReq {
  from: number,
  to: number,
  bank_name?: string
}

export interface IBankListItem {
  name: string,
  bank_id: number;
}


export interface IPayment {
  data: any,
  recipientData: {
    recipientName: string,
    recipientDataLoading: boolean
    recipientDataError: string,
  },
  transactionReady: boolean,
  loading: boolean,
  dataHistory: IPaymentHistoryItem[],
  historyTotal: number,
  loadingHistory: boolean,
  error: string,
  errorHistory: string,
  bankList: IBankListItem[],
  activeBankIndex: number | null,
  bankListTotal: number
}

export const createPayment = createAsyncThunk(
  'moneyTransfer/createPayment',
  async (payload: IGetPayment) => {
    try {
      const {data} = await ax().post('api/payments/create', payload);
      return data;
    } catch (e) {
      // @ts-ignore
      return e.response;
    }
  }
);

export const getRecipientDataSbp = createAsyncThunk(
  'moneyTransfer/getBankData',
  async (payload: IGetRecipientDataSbp) => {
    try {
      const {data} = await ax().post('api/bank/get-recipient-data-from-sbp', payload);
      return data;
    } catch (e) {
      console.log('error');
    }
  }
);

export const getBanksList = createAsyncThunk(
  'moneyTransfer/getBanksList',
  async (payload: IGetBankListReq, {dispatch}) => {
    try {
      const {data} = await ax().get('/api/bank/list', {params: {...payload}});

      return {data: data, payload: payload};
    } catch (e) {
      console.log('error');
    }
  }
);

export const getPaymentHistory = createAsyncThunk(
  'moneyTransfer/getPaymentHistory',
  async (payload, {dispatch}) => {
    try {
      const {data} = await ax().post('api/payments');
      return data;
      // return new Promise<any>((resolve) => {
      //   setTimeout(() => {
      //     resolve(transactionsData);
      //   }, 2345);
      // });
      // TODO позже надо убрать, но сейчас нужно для интеграции
    } catch (e) {
      console.log('error');
    }
  }
);

const MoneyTransferSlice = createSlice({
  name: 'moneyTransfer',
  initialState: {
    data: {},
    recipientData: {
      recipientName: '',
      recipientDataLoading: false,
      recipientDataError: '',
    },
    transactionReady: false,
    loading: false,
    dataHistory: [],
    historyTotal: 0,
    loadingHistory: false,
    error: '',
    errorHistory: '',
    bankList: [],
    activeBankIndex: null,
    bankListTotal: 0,
  },
  reducers: {
    clearError: (state: IPayment) => {
      state.error = '';
    },
    clearData: (state: IPayment) => {
      state.data = {};
    },
    clearBankList: (state: IPayment) => {
      state.bankList = [];
    },
    setBankActiveIndex: (state: IPayment, {payload}) => {
      state.activeBankIndex = payload;
    },
    setTransactionReady: (state: IPayment, {payload}) => {
      state.transactionReady = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(createPayment.fulfilled, (state: IPayment, action) => {
      state.loading = false;

      if (action.payload.data.code === 'ok') {
        state.data = action.payload.data;
        return;
      }
      if (action.payload.data.code === 'error' && action.payload.data.result) {
        state.error = action.payload.data.result;
        return;
      }
      const isError = action.payload.data.data.code === 'error' && action.payload.data.data.result;
      if (isError) {
        state.error = action.payload.data.data.result;
        return;
      }
    });
    builder.addCase(createPayment.pending, (state: IPayment) => {
      state.loading = true;
    });
    builder.addCase(createPayment.rejected, (state: IPayment) => {
      state.loading = false;
    });
    builder.addCase(getPaymentHistory.fulfilled, (state: IPayment, action) => {
      state.loadingHistory = false;
      if (action.payload.data.code === 'error' && action.payload.data.result) {
        state.errorHistory = action.payload.data.result;
      } else if (action.payload.data.code === 'ok') {
        state.dataHistory = action.payload.data.result.items;
        state.historyTotal = action.payload.data.result.totla;
      }
    });
    builder.addCase(getPaymentHistory.pending, (state: IPayment) => {
      state.loadingHistory = true;
    });
    builder.addCase(getPaymentHistory.rejected, (state: IPayment) => {
      state.loadingHistory = false;
    });
    builder.addCase(getBanksList.fulfilled, (state: IPayment, {payload}) => {
      if (!payload) {
        return;
      }
      if (payload.data.data.code === 'error') {
        return;
      }
      if (payload.payload.from > 0) {
        state.bankList = state.bankList.concat(payload.data.data.result.items);
      } else {
        state.bankList = payload.data.data.result.items;
      }
      if (payload.data.data.result.total) {
        state.bankListTotal = payload.data.data.result.total;
      }
      return;
    });
    builder.addCase(getBanksList.pending, (state: IPayment) => {
      return;
    });
    builder.addCase(getBanksList.rejected, (state: IPayment) => {
      return;
    });
    builder.addCase(getRecipientDataSbp.fulfilled, (state: IPayment, {payload}) => {
      state.recipientData.recipientDataLoading = false;
      if (!payload) {
        state.transactionReady = false;
        state.recipientData.recipientDataError = 'Что-то пошло не так, проверьте данные';
        return;
      }
      if (payload.data.code === 'error') {
        state.transactionReady = false;
        state.recipientData.recipientDataError = payload.data.result;
        return;
      }
      state.recipientData.recipientName = payload.data.result.name;
      state.transactionReady = true;
      return;
    });
    builder.addCase(getRecipientDataSbp.pending, (state: IPayment) => {
      state.recipientData.recipientDataLoading = true;

      return;
    });
    builder.addCase(getRecipientDataSbp.rejected, (state: IPayment) => {
      state.recipientData.recipientDataLoading = false;
      return;
    });
  },
});
export const {
  clearError,
  clearData,
  clearBankList,
  setBankActiveIndex,
  setTransactionReady
} = MoneyTransferSlice.actions;

export default MoneyTransferSlice.reducer;
