export const queryParsing = (): {[key: string]: string} => {
  const search = window.location.search;
  if (!search) {
    return {};
  }
  const str = search.replace('?', '');
  const arr = str.split('&');
  const data = arr.reduce((acc:any, item) => {
    const intermediate = item.split('=');
    return acc = {[intermediate[0]]: intermediate[1], ...acc};
  }, {});

  return data;
};
