import React, {useEffect, useState} from 'react';

import {useAppDispatch, useAppSelector} from 'utils/hooks';

import {
  getQtyCardInNominals,
  handleCounter,
  INominal,
  INominalsToOrder,
  setLoading,
  setVisibleForm
} from 'store/components/PrizesSlice';
import {GetIcon} from 'store/enums/normalizedIcons';

import Counter from 'components/counter/Counter';
import WindowLoading from 'components/windowLoading/WindowLoading';
import NominalTableItem from './assets/components/NominalTableItem';

import './NominalTable.scss';

const NominalTable = ({error ,visibleError}: { error: boolean, visibleError: () => void }) => {
  const colors = useAppSelector(state => state.settings.colors);
  const domain = useAppSelector(state => state.settings.currentDomainName);

  const dispatch = useAppDispatch();

  const card_data = useAppSelector(state => state.prizes.card);
  const {orderCard} = useAppSelector(state => state.popup.popup);

  const [visibleListWithTypeNo, setVisibleListWithTypeNo] = useState(true);

  const lengthListWithTypeNo = card_data.nominals
    ? Object.values(card_data.nominals).filter((item: INominal) => item.qty <= 0).length
    : null;

  const clear = (id: string) => {
    dispatch(handleCounter({id, mode: 'clear'}));
  };

  if (!card_data) {
    return <></>;
  }

  return (
    <>
      <div className={'nominal-table__content'}>
        <div
          className={'nominal-table__container'}>
          <div className={'nominal-table'}>
            <WindowLoading id={'nominalTable'} className={'nominal-table__loading'} />
            <div className={'nominal-table__label-container'}>
              <div className={'nominal-table__label nominal-table__label_hide'}>Номинал</div>
              {card_data.markup ? (
                <div className={'nominal-table__label nominal-table__label_hide'}>Комиссия</div>
              ) : <div className={'nominal-table__label'}></div>}
              <div className={'nominal-table__label nominal-table__label_hide'}>В наличии, шт.</div>
              <div
                className={'nominal-table__label nominal-table__label_hide-desktop nominal-table__label_show'}>
                  Номинал / В наличии, шт.
              </div>
              <div className={'nominal-table__label'}>Количество</div>
              <div style={{width: '45px'}}></div>
            </div>
            <div style={{height: 'auto'}}>
              <div className={'nominal-table__table-container'}>
                {
                  Object.entries(card_data.nominals).filter((item ) => {
                    return item[1].qty > 0;
                  })
                    .map((item: any) => (
                      <NominalTableItem
                        key={item.id}
                        item={item}
                        visibleError={visibleError}
                        clear={clear}
                        markup={card_data.markup ? card_data.markup : ''}
                      />
                    ))
                }
                <div
                  className={`
                  nominal-table__info-container 
                  nominal-table__info-container_yellow 
                  ${!lengthListWithTypeNo ? 'nominal-table__info-container_hide' : ''}
                `}>
                  <div className={'nominal-table__line-between-list-container'}>
                    <div className={'nominal-table__nominal nominal-table__line-between-list'}>
                      Заказ с ожиданием {card_data.validity_period_nominal}
                    </div>
                  </div>
                  <div className={'nominal-table__qty'}></div>
                  {card_data.markup ? null : (<div className={'nominal-table__qty'}></div>)}
                  <div className={'nominal-table__counter'}></div>
                  <div className={'nominal-table__cell'}></div>
                  <div className={'nominal-table__qty nominal-table__qty_desktop'}></div>
                  <div
                    onClick={() => setVisibleListWithTypeNo(innerVisible => !innerVisible)}
                    className={'nominal-table__cross nominal-table__cross_text-replace-icon'}>
                    {visibleListWithTypeNo ? 'скрыть' : 'раскрыть'}
                  </div>
                </div>
                {
                  Object.entries(card_data.nominals)?.filter((item ) => item[1].qty <= 0)
                    .map((item: any) => (
                      <div
                        key={item[0]}
                        className={`
                        nominal-table__info-container 
                        nominal-table__info-container_red 
                        ${item.counter > 0 ? 'nominal-table__info-container_selected' : ''}
                        ${!visibleListWithTypeNo ? 'nominal-table__info-container_hide' : ''}
                      `}>
                        <div
                          className={
                            item[1].qty
                              ? 'nominal-table__nominal'
                              : 'nominal-table__nominal nominal-table__nominal_color-blue'
                          }>
                          {item[1].nominal}&nbsp;<span className={'nominal-table__nominal_color-grey'}>₽</span>
                        </div>
                        {card_data.markup ? (
                          <div
                            className={`
                            nominal-table__markup 
                            ${!item[1].count ? 'nominal-table__nominal_color-blue' : ''}
                          `}>
                            {Math.floor(+item[1].nominal / 100 * parseInt(card_data.markup))} <span className={'nominal-table__nominal_color-grey'}>₽</span>
                          </div>
                        ) : (<div className={'nominal-table__markup'}></div>)}
                        <div className={'nominal-table__qty'}>
                          <div className={'nominal-table__qty_zero'}>
                            под заказ {card_data.validity_period_nominal}
                          </div>
                        </div>
                        <div className={'nominal-table__nominal-with-qty'}>
                          <div className={'nominal-table__nominal-with-qty-cell'}>
                            <div
                              className={`
                              nominal-table__markup  
                              nominal-table__nominal_mobile-show 
                              ${item[1].qty ? 'nominal-table__nominal_color-blue' : ''}
                            `}>
                              {item[1].nominal}&nbsp;<span className={'nominal-table__nominal_color-grey'}>₽</span>
                            </div>
                            &nbsp;{'/'}&nbsp;
                            <div className={'nominal-table__qty nominal-table__qty_mobile-show'}>
                              <div className={'nominal-table__qty_zero'}>
                                под заказ {card_data.validity_period_nominal}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={'nominal-table__counter'}>
                          <Counter error={visibleError} item={item} modeCounter={'orderCard'} />
                        </div>
                        <div onClick={() => clear(item[0])} className={'nominal-table__cross'}>
                          {
                            item[1].count > 0
                              ? (
                                <GetIcon
                                  iconName={'Cross'}
                                  fill={colors[domain].crossIcon.mainIcon}
                                  secondaryFill={colors[domain].crossIcon.secondIcon}
                                  opacity={colors[domain].crossIcon.opacity}
                                />
                              ) : <div style={{width: 17}} />
                          }
                        </div>
                      </div>
                    ))
                }
              </div>

            </div>
          </div>
        </div>
      </div>
      <div className={'nominal-table__error'}>{error ? 'Недостаточно средств в кошельке' : ' '}</div>
    </>
  );
};

export default NominalTable;
