import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';

import {ndfl_value} from 'env/env.json';

import {useAppDispatch, useAppSelector} from 'utils/hooks';
import {findLink} from 'utils/functions';

import {updateCart} from 'store/components/CartSlice';
import {hidePopup, showPopup} from 'store/components/PopupSlice';
import {clearOrderCard, ICardData, INominal, INominalsToOrder, setInCart} from 'store/components/PrizesSlice';
import {GetIcon} from 'store/enums/normalizedIcons';

import Button from 'components/button/Button';
import Popup from 'components/popup/Popup';

import NominalTable from './assets/components/nominalTable/NominalTable';
import SelectStepNominals from './assets/components/selectStepNominals/SelectStepNominals';
import SelectorStepNominals from './assets/components/selectorStepNominals/SelectorStepNominals';
import {ArrowLink, Cart, ClipBoard, CommissionIcon, InfoIcon, Tile} from 'assets/images/IconPack';

import './OrderCard.scss';

const OrderCard = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const card_data = useAppSelector(state => state.prizes.card);
  const {orderCard} = useAppSelector(state => state.popup.popup);
  const purse = useAppSelector(state => state.dashboard.purse);
  const total = useAppSelector(state => state.cart.total);
  const total_ndfl = useAppSelector(state => state.cart.total_tax);
  const colors = useAppSelector(state => state.settings.colors);
  const domain = useAppSelector(state => state.settings.currentDomainName);

  const [isBuying, setIsBuying] = useState(false);
  const [visibleFullDescription, setVisibleFullDescription] = useState<boolean>(false);
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const [error, setError] = useState(false);

  const visibleError = () => {
    setError(true);
  };

  const descriptionLen = 110;

  const sumTotal = Object.values(card_data?.nominals).reduce(
    (previousValue: number, currentValue: INominal) =>
      previousValue + (+currentValue.nominal * +currentValue.count), 0) || 0;

  const ndflTotal =
    Object.values(card_data?.nominals).reduce(
      (previousValue: number, currentValue: INominal) => {
        const NDFL = purse?.tax_payer === 'participant' && purse.is_taxable_eps
          ? Math.round(+currentValue.nominal / ndfl_value - +currentValue.nominal) * currentValue.count
          : 0;
        return previousValue + NDFL;
      }, 0) || 0;

  const markupTotal = Object.values(card_data?.nominals).reduce(
    (previousValue: number, currentValue: INominal) =>
      previousValue + (Math.floor(+currentValue.nominal / 100 * parseInt(card_data.markup ? card_data.markup : '0')) * +currentValue.count), 0) || 0;

  const description = visibleFullDescription || card_data?.description.length <= descriptionLen
    ? card_data.description
    : `${card_data?.description.slice(0, descriptionLen)}...`;

  const disabledButton = sumTotal === 0 || +sumTotal + +total > +(purse?.balance || 0);

  const moreTotalToBalance = card_data.nominals ?
    Object.values(card_data?.nominals).filter(
      (nominal: INominal) => {
        return (sumTotal + markupTotal + ndflTotal) + (+total + (total_ndfl ? total_ndfl : 0)) <= (purse?.balance || 0);
      }
    ).length > 0 || Object.keys(card_data?.nominals).length <= 0
    : null;

  const closeOrderCard = () => {
    setLoadingButton(true);
    dispatch(updateCart()).then(() => {
      dispatch(setInCart(card_data));
      setLoadingButton(false);
      setIsBuying(true);
      dispatch(clearOrderCard());
    });
  };

  const closeModal = () => {
    setIsBuying(false);
    dispatch(hidePopup('orderCard'));
  };

  const goToCart = () => {
    dispatch(hidePopup('orderCard'));
    setIsBuying(false);
    history.push('cart');
  };
  const openTermsOfUse = (id: string) => {
    dispatch(showPopup({popup: 'termsOfUse', props: id}));
  };

  useEffect(() => {
    if (card_data) {
      setVisibleFullDescription(false);
    }
  }, [orderCard]);

  useEffect(() => {
    if (!isBuying && !moreTotalToBalance) {
      setError(true);
    }
    if (moreTotalToBalance) {
      setError(false);
    }
  }, [moreTotalToBalance, sumTotal, markupTotal, ndflTotal]);

  if (!card_data) {
    return null;
  }

  return (
    <Popup id={'orderCard'} onClose={closeModal} className={'order-card__popup'}>
      <div className={'order-card'}>
        <div className={'order-card__row'}>
          <div className={'order-card__info-container'}>
            <img src={card_data.file_link} alt="card" className={'order-card__logo'}/>
            {
              card_data.description ? (
                <div className={'order-card__description-container'}>
                  <p className={'order-card__description'}>{description}</p>
                  {
                    card_data?.description.length > descriptionLen ? (
                      <a
                        className={'order-card__link order-card__link_flex'}
                        onClick={() => setVisibleFullDescription(innerValue => !innerValue)}>
                        {!visibleFullDescription ? 'Подробнее' : 'Свернуть'}
                      </a>
                    ) : null
                  }
                </div>
              ) : null
            }
            {
              card_data.terms_of_use ? (
                <div className={'order-card__rules-container'} onClick={() => openTermsOfUse('TermsOfUse')}>
                  <span className={'order-card__clipboard'}>
                    <GetIcon iconName={'ClipBoard'} fill={colors[domain].firstClipBoardColor}/>
                  </span>
                  <span className={'order-card__rules-title'}>Правила использования подарочного сертификата</span>
                </div>
              ): null
            }
            {
              card_data.limitations ? (
                <div
                  className={'order-card__rules-container order-card__rules-container_red'}
                  onClick={() => openTermsOfUse('Limitations')}>
                  <span className={'order-card__clipboard'}>
                    <GetIcon iconName={'ClipBoard'} fill={colors[domain].secondClipBoardColor} />
                  </span>
                  <span className={'order-card__rules-title'}>Ограничения на использование сертификата</span>
                </div>
              ): null
            }
          </div>
          <div className={'order-card__nominals-container'}>
            <h2 className={'order-card__title'}>{card_data.name}</h2>
            <div className={'order-card__scroll-container'}>
              {
                card_data.validity_period ? (
                  <p
                    className={`
                      order-card__issuer-info order-card__issuer-info_mobile
                    `}>
                    <span className={'order-card__info-label'}>Срок действия{' '}</span>
                    <span className={'order-card__date'}>{card_data.validity_period}</span>
                  </p>
                ) : null
              }
              {
                card_data.reception_locations ? (
                  <p
                    className={`
                      order-card__issuer-info 
                      order-card__issuer-info_margin-bottom 
                      order-card__issuer-info_mobile
                    `}>
                    <span className={'order-card__info-label'}>Места приема{' '}</span>
                    <a
                      href={findLink(card_data.reception_locations) || '#'}
                      target={'_blank'}
                      className={'order-card__link-issuer'}
                      rel="noreferrer">
                      {findLink(card_data.reception_locations) || card_data.reception_locations}
                    </a>
                  </p>
                ) : null
              }
              <div className={'order-card__warning order-card__warning_mobile'}>
                <span className={'order-card__warning-icon'}>
                  <GetIcon
                    iconName={'InfoIcon'}
                    fill={colors[domain].infoIcon}
                    secondaryFill={colors[domain].infoIcon}
                  />
                </span>
                <span>Информация о наличии обновляется каждые 5 минут</span>
              </div>
              <div className={'order-card__info-container order-card__info-container_mobile'}>
                <div className={'order-card__logo-container'}>
                  <img src={card_data.file_link} alt="card" className={'order-card__logo'}/>
                  <div className={'order-card__terms-container'}>
                    {
                      card_data.terms_of_use ? (
                        <div
                          className={'order-card__rules-container order-card__rules-container_mobile'}
                          onClick={() => openTermsOfUse('TermsOfUse')}>
                          <span className={'order-card__clipboard'}>
                            <GetIcon iconName={'ClipBoard'} fill={colors[domain].firstClipBoardColor} />
                          </span>
                          <span className={'order-card__rules-title order-card__rules-title_mobile'}>
                            Правила использования подарочного сертификата
                          </span>
                        </div>
                      ) : null
                    }
                    {
                      card_data.limitations ? (
                        <div
                          className={`
                            order-card__rules-container 
                            order-card__rules-container_red
                            order-card__rules-container_mobile
                          `}
                          onClick={() => openTermsOfUse('Limitations')}>
                          <span className={'order-card__clipboard'}>
                            <GetIcon iconName={'ClipBoard'} fill={colors[domain].secondClipBoardColor} />
                          </span>
                          <span className={'order-card__rules-title order-card__rules-title_mobile'}>
                            Ограничения на использование сертификата
                          </span>
                        </div>
                      ) : null
                    }
                  </div>
                </div>
                {
                  card_data.description ? (
                    <div>
                      <p className={'order-card__description'}>{description}</p>
                      {
                        card_data?.description.length > descriptionLen ? (
                          <a
                            className={'order-card__link order-card__link_flex'}
                            onClick={() => setVisibleFullDescription(innerValue => !innerValue)}>
                            {!visibleFullDescription ? 'Подробнее' : 'Свернуть'}
                          </a>
                        ) : null
                      }
                    </div>
                  ) : null
                }
              </div>
              {
                card_data.validity_period_nominal ? (
                  <p className={'order-card__issuer-info order-card__issuer-info_desktop'}>
                    <span className={'order-card__info-label'}>Срок действия{' '}</span>
                    <span className={'order-card__date'}>{card_data.validity_period}</span>
                  </p>
                ) : null
              }
              {
                card_data.reception_locations ? (
                  <p
                    className={`
                      order-card__issuer-info 
                      order-card__issuer-info_desktop 
                      order-card__issuer-info_margin-bottom
                    `}>
                    <span className={'order-card__info-label'}>Места приема{' '}</span>
                    <a
                      href={findLink(card_data.reception_locations) || '#'}
                      target={'_blank'}
                      className={'order-card__link-issuer'}
                      rel="noreferrer">
                      {findLink(card_data.reception_locations) || card_data.reception_locations}
                    </a>
                  </p>
                ) : null
              }
              <div className={'order-card__warning order-card__warning_desktop'}>
                <span className={'order-card__warning-icon'}>
                  <GetIcon
                    iconName={'InfoIcon'}
                    fill={colors[domain].infoIcon}
                    secondaryFill={colors[domain].infoIcon}
                  />
                </span>
                <span>Информация о наличии обновляется каждые 5 минут</span>
              </div>
              <div className={'order-card__table'}>
                <div className={'order-card__info-container_desktop'}>
                  {card_data.name === 'Ozon' && card_data.markup ? (
                    <div className={'order-card__commission-info'}>
                      <CommissionIcon />
                      <div className={'order-card__commission-info-text'}>
                        Обменивается с комиссией
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className={'order-card__table-container'}>
                  <SelectorStepNominals onClick={closeModal} total={sumTotal + markupTotal} purse={purse} disabled={error} />
                  <NominalTable error={error} visibleError={visibleError} />
                </div>
              </div>
              {
                sumTotal > 0
                  ? (
                    <div className={'order-card__total-container'}>
                      <div className={'order-card__price-container'}>
                        <div className={'order-card__markup-container'}>
                          {ndflTotal
                            ? (
                              <div className={'order-card__total-container-item'}>
                                <span className={'order-card__ndfl-label'}>
                                  НДФЛ:&nbsp;&nbsp;
                                  <span className={'order-card__ndfl-sum'}>
                                    {Math.round(ndflTotal)}
                                    <span className={'order-card__sum_color-blue'}> ₽</span>
                                  </span>
                                </span>
                              </div>
                            )
                            : null
                          }
                          {markupTotal
                            ? (
                              <span className={'order-card__total-container-item'}>
                                <span className={'order-card__commission-label'}>Общая комиссия:&nbsp;&nbsp;</span>
                                <span className={'order-card__commission-sum'}>
                                  {Math.round(markupTotal)}
                                  <span className={'order-card__sum_color-blue'}> ₽</span>
                                </span>
                              </span>
                            )
                            : null
                          }
                        </div>
                        <div className={'order-card__total-container-item'}>
                          <span className={'order-card__total-label'}>Итого:&nbsp;&nbsp;</span>
                          <span className={'order-card__sum'}>
                            {sumTotal + markupTotal + ndflTotal}
                            <span className={'order-card__sum_color-blue'}> ₽</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : null
              }
            </div>
          </div>
        </div>
        <div className={'order-card__button-container'}>
          {
            !sumTotal
              ? null
              : (
                <Button
                  icon={<GetIcon iconName={'Cart'} opacity={'1'}/>}
                  loading={loadingButton}
                  disabled={disabledButton || loadingButton}
                  onClick={closeOrderCard}
                  className={'order-card__button'}
                  iconFloat={'right'}>
                  <span
                    className={'order-card__button-text_mobile'}>
                    {sumTotal + markupTotal + ndflTotal} ₽ -&nbsp;
                  </span>
                  Добавить в корзину
                </Button>
              )
          }
          {
            isBuying && !sumTotal
              ? (
                <div className={'order-card__button'}>
                  <a
                    className={`
                      order-card__link 
                      order-card__link_underline 
                      order-card__continue-link
                      order-card__continue-link_desktop
                    `}
                    onClick={closeModal}>
                    Продолжить покупки
                    <span className={'order-card__continue-link-icon'}>
                      <GetIcon
                        iconName={'Tile'}
                        fill={colors[domain].continueIconColor}
                        secondaryFill={colors[domain].continueIconColor}
                      />
                    </span>
                  </a>
                  <a
                    className={`
                      order-card__continue-link
                      order-card__continue-link_mobile
                      order-card__link 
                    `}
                    onClick={closeModal}>
                    В каталог
                    <span className={'order-card__continue-link-icon'}>
                      <GetIcon
                        iconName={'Tile'}
                        fill={colors[domain].continueIconColor}
                        secondaryFill={colors[domain].continueIconColor}
                      />
                    </span>
                  </a>
                  <Button
                    icon={<GetIcon iconName={'ArrowLink'} fill={colors[domain].logoIconColor} />}
                    className={'order-card__button_mobile'}
                    onClick={goToCart}
                    iconFloat={'right'}>
                    В корзину
                  </Button>
                  <Button
                    icon={<GetIcon iconName={'Cart'} opacity={'1'}/>}
                    className={'order-card__button_desktop'}
                    onClick={goToCart}
                    iconFloat={'right'}>
                    Перейти в корзину
                  </Button>
                </div>
              ) : null
          }
        </div>
      </div>
    </Popup>
  );
};

export default OrderCard;
