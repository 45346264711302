import React, {useEffect, useState} from 'react';

import {useAppDispatch, useAppSelector} from 'utils/hooks';

import {getCategories, setChangeSearchData, setLoading, setSearchFilter} from 'store/components/PrizesSlice';
import {showPopup} from 'store/components/PopupSlice';
import {GetIcon} from 'store/enums/normalizedIcons';

// import BannerPepsico from './assets/components/bannerPepsico/BannerPepsico';
// import Banner from './assets/components/banner/Banner'; // Баннер который нужен был на новый год, думаю нужен будет в след новом году
import Search from '../search/Search';
import Banner from './assets/components/banner/Banner';
import PurseList from '../purseList/PurseList';
import CategoriesAvailabilitySelector from '../categoriesAvailabilitySelector/CategoriesAvailabilitySelector';
import NDFL_Banner from 'pages/dashboard/assets/components/NDFL_Banner/NDFL_Banner';

import './HeaderPrizes.scss';

const HeaderPrizes = () => {
  const dispatch = useAppDispatch();
  const href = window.location.href;

  const user = useAppSelector((state) => state.auth.user);
  const purse = useAppSelector(state => state.dashboard.purse);
  const cardsFilterLength = useAppSelector(state => state.prizes.cardsTotal);
  const colors = useAppSelector((state) => state.settings.colors);
  const domain = useAppSelector((state) => state.settings.currentDomainName);
  const searchQuery = useAppSelector((state) => state.prizes.searchQuery);

  const [search, setSearch] = useState<string>('');

  if (!user) {
    return null;
  }

  const onClickSetting = () => {
    dispatch(showPopup('settingPopup'));
  };

  const suffix = () => {
    if (!cardsFilterLength) {
      return 'a';
    }
    const count = Number(`${cardsFilterLength}`[`${cardsFilterLength}`.length - 1]);
    if (count >= 5 || count === 0 || count > 10 && count <= 20) {
      return 'ов';
    }
    if (count === 1) {
      return '';
    }
    return 'a';
  };

  const changeReflecterator = () => {
    dispatch(setChangeSearchData(true));
    setTimeout(() => {
      dispatch(setChangeSearchData(false));
    }, 0);
  };

  useEffect(() => {
    if (search){
      dispatch(setSearchFilter(search));
      changeReflecterator();
    } else {
      dispatch(setSearchFilter(''));
      changeReflecterator();
    }
  }, [search]);

  useEffect(() => {
    if (purse?.id) {
      dispatch(getCategories()).then(() => {
        setTimeout(() => dispatch(setLoading({name: 'categories', value: false})), 2000);
      });
    }
  }, [purse?.id]);

  return (
    <div className={'header-prizes'}>
      {/*{href.includes('pepsico') ? (<BannerPepsico />) : null}*/}
      <h2 className={'header-prizes__title'}>
        Каталог электронных сертификатов
      </h2>
      <Banner />
      {/*//Баннер который нужен был на новый год, думаю нужен будет в след новом году*/}
      <PurseList />
      {purse?.has_tax ? (
        <NDFL_Banner />
      ) : null}
      <div className={'header-prizes__catalog-counter'}>
        В каталоге - {cardsFilterLength} бренд{suffix()}
      </div>
      <div className={'header-prizes__info-container'}>
        <div className={'header-prizes__row'}>
          <div className={'header-prizes__categories header-prizes__selector-container_desktop'}>
            <CategoriesAvailabilitySelector selectorType={'categories'}/>
          </div>
        </div>
        <div className={'header-prizes__search-container'}>
          <Search value={searchQuery} setValue={setSearch} />
        </div>
        <div className={'header-prizes__availability header-prizes__selector-container_desktop'}>
          <CategoriesAvailabilitySelector selectorType={'availability'}/>
        </div>
        <div
          onClick={onClickSetting}
          className={'header-prizes__icon-container header-prizes__selector-container_mobile'}>
          <GetIcon
            iconName={'SettingIcon'}
            fill={colors[domain].firstColorIconIdle}
            secondaryFill={colors[domain].secondColorIconIdle}
          />
        </div>
      </div>
    </div>
  );
};

export default HeaderPrizes;
