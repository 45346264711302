import React from 'react';
import {useSelector} from 'react-redux';

import {IStore} from 'store/store';

import Link from 'components/link/Link';
import RulesAndPers from 'components/rulesAndPers/RulesAndPers';

import logo from './assets/image/logo.svg';
import jivochat from './assets/image/jivochat.svg';
import telegram from './assets/image/telegram.svg';

import './Footer.scss';

interface IFooter {
  className?: string;
}

const Footer: React.FC<IFooter> = ({className}) => {
  const authorized = useSelector((state: IStore) => state.auth.authorized);

  const openJivo = () => {
    // @ts-ignore
    jivo_api.open();
    return false;
  };

  const inlineStyleFooter = {
    backgroundColor: authorized ? '#fff' : 'transparent',
  };

  return (
    <footer
      style={inlineStyleFooter}
      className={`
        footer 
        ${className} 
        ${authorized ? 'footer_auth' : ''}
      `}>
      <div className={'footer__background'}>
        <div className={'footer__container'}>
          <div className={'footer__logo-container footer__logo-container_mobile'}>
            <img src={logo} alt="logo" className={'footer__logo'}/>
          </div>
          <div className={'footer__info-container'}>
            <RulesAndPers />
            <div className={'footer__logo-container footer__logo-container_desktop'}>
              <img src={logo} alt="logo" className={'footer__logo'}/>
            </div>
            <div className={'footer__help'}>
              <div className={'footer__logo-container footer__logo-container_tablet'}>
                <img src={logo} alt="logo" className={'footer__logo'}/>
              </div>
              <p className={'footer__help-label'}>
                Получить консультацию:
              </p>
              <div className={'footer__help-container'}>
                <div className={'footer__jivochat-container'} onClick={openJivo}>
                  <img src={jivochat} alt="jivochat" className={'footer__jivochat-icon'} />
                  <Link color={'#5f5f6bb3'}>Jivo-чат</Link>
                </div>
                <a
                  href={'https://t.me/presentcard_bot'}
                  target={'_blank'}
                  rel="noreferrer"
                  className={'footer__telegram-container'}>
                  <img src={telegram} alt="telegram" className={'footer__telegram-icon'}/>
                  <Link color={'#5f5f6bb3'}>presentcard_bot</Link>
                </a>
              </div>

              {window.location.href.includes('pepsi')
                ? (
                  <span className={'footer__phone-info-container'}>
                    Тел.:&nbsp;
                    <a className={'footer__phone'} href={'tel:+7 (499) 455-99-35'}>+7 (499) 455-99-35</a>
                  </span>
                )
                : null}
            </div>
          </div>

        </div>
      </div>
    </footer>
  );
};

export default Footer;
