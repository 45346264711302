import React, {useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';

import {
  clearError,
  createPayment,
  clearData,
  getBanksList,
  setBankActiveIndex,
  clearBankList,
  setTransactionReady,
} from 'store/components/MoneyTransferSlice';
import {hidePopup, showPopup} from 'store/components/PopupSlice';
import {useAppDispatch, useAppSelector} from 'utils/hooks';

import Checkbox from 'components/checkbox/Checkbox';
import SecondInput from 'components/secondInput/SecondInput';
import Button from 'components/button/Button';
import Input from 'components/input/Input';

import arrow from '../../images/arrow.svg';

import {ndfl_value} from 'env/env.json';

import './MoneyTransferTabs.scss';

const MAX_SUM_CARD = 14500;
const MAX_SUM_PHONE = 5000;

interface IBaseType {
  sum: string,
  setSum: (data: string) => void,
  setErrorSum: (data: string) => void,
  markup: string,
  NDFL: string,
  setNDFL: (data: string) => void,
  setMarkup: (data: string) => void,
  balance?: number,
  enabledNDFL: boolean,
}

interface IPhoneAndCardComponent extends IBaseType {
  mask: (string | RegExp)[],
  value: string,
  setValue: (data: string) => void,
  title: string,
  commission: number,
  error: string
}

interface ISPB extends IBaseType {
  mask: (string | RegExp)[],
  phone: string,
  setPhone: (data: string) => void,
  commission: number,
}

const SumComponent = (
  {
    sum,
    setSum,
    markup,
    setMarkup,
    balance,
    isPhone,
    commission,
    setErrorSum,
    setNDFL,
    NDFL,
    enabledNDFL,
  }: any) => {
  const purse = useAppSelector(state => state.dashboard.purse);
  const moreThanAvailable = parseInt(sum) + parseFloat(markup) > balance;
  const error =
    moreThanAvailable
      ? 'Сумма не должна превышать доступные средства'
      : parseInt(sum) > (isPhone ? MAX_SUM_PHONE : MAX_SUM_CARD)
        ? `Максимальная сумма не более ${isPhone ? MAX_SUM_PHONE : MAX_SUM_CARD}`
        : '';

  const changeSum = (value: string, type = 'nominal', placeholder = '') => {
    if (value < sum) {
      value = value.substring(0, value.length - (placeholder.length + 1));
    }

    const cleanedValue = value.replace(/\D/g, '');
    const formattedValue = `${cleanedValue} ${type === 'nominal' ? placeholder : placeholder}`;

    if (parseInt(cleanedValue) <= 0) {
      setSum('');
      return;
    }

    if (value.length) {
      setSum(formattedValue);
      if (purse?.min_payment_commission) {
        setMarkup(Math.floor(parseInt(cleanedValue) / 100 * commission >= purse?.min_payment_commission
          ? parseInt(cleanedValue) / 100 * commission
          : purse?.min_payment_commission));
      }
    } else {
      setSum('');
      setMarkup('');
    }
  };

  useEffect(() => {
    setErrorSum(error);
  }, [error]);

  return (
    <div className={'money-transfer-tabs__sum-component'}>
      <SecondInput
        title={'Введите сумму, ₽'}
        error={error}
        value={sum}
        setValue={changeSum}
        placeholder={`до ${isPhone ? balance > MAX_SUM_PHONE ? MAX_SUM_PHONE : balance : balance > MAX_SUM_CARD ? MAX_SUM_CARD : balance}`}
        className={'money-transfer-tabs__sum-color money-transfer-tabs__sum'}
        errorBG={'#FF4B40'}
        errorColor={'#FFDCE7'}
      />
      {
        purse?.min_payment_commission && purse?.min_payment_commission > 0 ? (
          <SecondInput
            title={'Комиссия, ₽'}
            value={markup}
            setValue={setMarkup}
            placeholder={'0'}
            className={`money-transfer-tabs__ndfl-markup-color ${enabledNDFL ? 'money-transfer-tabs__markup' : 'money-transfer-tabs__sum'}`}
            disabled={true}
            errorColor={'#FFDCE7'}
          />
        ) : null
      }
      {
        enabledNDFL ? (
          <SecondInput
            title={'НДФЛ, ₽'}
            value={NDFL}
            setValue={setNDFL}
            placeholder={'0'}
            className={'money-transfer-tabs__ndfl money-transfer-tabs__ndfl-markup-color'}
            disabled={true}
            errorColor={'#FFDCE7'}
          />
        ) : null
      }
    </div>
  );
};

const SBP = ({
  sum,
  setSum,
  markup,
  setMarkup,
  mask,
  phone,
  setPhone,
  balance,
  setErrorSum,
  commission,
  setNDFL,
  NDFL,
  enabledNDFL,
}: ISPB) => {
  const dispatch = useAppDispatch();
  const containerRef = useRef<any>(null);
  const bankList = useAppSelector((state) => state.payments.bankList);
  const totalBankList = useAppSelector((state) => state.payments.bankListTotal);
  const activeBankIndex = useAppSelector((state) => state.payments.activeBankIndex);
  const [step, setStep] = useState(0);
  const [show, setShow] = useState(false);
  const [bankName, setBankName] = useState('');

  const ratio = 10;

  useEffect(() => {
    if (Math.floor(totalBankList / ratio) >= step) {
      dispatch(getBanksList({from: step * ratio, to: (step + 1) * ratio, bank_name: bankName ? bankName : undefined}));
    }
  }, []);

  useEffect(() => {
    if (activeBankIndex !== null) {
      if (bankList[activeBankIndex]) {
        setBankName(bankList[activeBankIndex].name);
      }
    }
  }, [activeBankIndex]);

  const changeBank = ({index}: {index: number}) => {
    dispatch(setBankActiveIndex(index));
    setShow(false);
  };

  let timer: any;

  useEffect(() => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      if (bankName) {
        dispatch(getBanksList({from: 0, to: ratio, bank_name: bankName ? bankName : undefined}));
      }
    }, 500);

    setStep(0);
    return () => clearTimeout(timer);
  }, [bankName]);

  useEffect(() => {
    if (containerRef.current !== null) {
      const handleScroll = () => {
        const container = containerRef.current;
        const containerRect = container.getBoundingClientRect();

        const list = container.children[container.children.length - 1];
        const listRect = list.getBoundingClientRect();

        if (listRect.bottom <= containerRect.bottom) {
          if (totalBankList >= step * 10) {
            dispatch(getBanksList({from: step * ratio, to: (step + 1) * ratio, bank_name: bankName ? bankName : undefined}));
          }
          setStep(prev => prev + 1);
        }
      };

      containerRef.current.addEventListener('scroll', handleScroll);

      return () => {
        containerRef.current?.removeEventListener('scroll', handleScroll);
      };
    }
  }, [step]);

  return (
    <div className={'money-transfer-tabs__spb'}>
      <div>
        <span className={'money-transfer-tabs__input-title'}>
          Номер телефона получателя
        </span>
        <Input background={'#FFFFFF'} placeholder={'+7 XXX XXX-XX-XX'} value={phone} setValue={setPhone} mask={mask} />
      </div>
      <SumComponent
        setErrorSum={setErrorSum}
        sum={sum}
        markup={markup}
        commission={commission}
        setSum={setSum}
        setMarkup={setMarkup}
        balance={balance}
        setNDFL={setNDFL}
        NDFL={NDFL}
        enabledNDFL={enabledNDFL}
      />
      <div className={'money-transfer-tabs__selector-input'}>
        <span className={'money-transfer-tabs__input-title'}>
          Банк получателя
        </span>
        <div
          onClick={() => setShow(!show)}
          className={`
            money-transfer-tabs__selector-display 
            money-transfer-tabs__selector-display_${show ? 'active' : ''}
          `}>
          <input
            className={'input'}
            placeholder={'Выбрать из списка'}
            value={bankName}
            onChange={(e) => {
              const value = e.target.value.trimLeft();
              if(/^[a-zA-Zа-яА-Я0-9@./ '&_-]+$/.test(value)) {
                setBankName(value);
              }
              if (value.length === 0) {
                setBankName('');
              }
              if (!show) {
                setShow(true);
              }
            }}
          />
          <img src={arrow} alt={'arrow'}/>
        </div>
        <div
          ref={containerRef}
          className={`
            money-transfer-tabs__selector-list 
            ${show ? 'money-transfer-tabs__selector-list_open' : ''}
          `}>
          {bankList.map((item, index) => {
            return (
              <div
                onClick={() => {
                  changeBank({index});
                }}
                className={'money-transfer-tabs__selector-item'}
                key={item.name + index}>
                {item.name}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const PhoneAndCard = (
  {
    sum,
    setSum,
    markup,
    setMarkup,
    mask,
    value,
    setValue,
    setErrorSum,
    title,
    balance,
    commission,
    error,
    setNDFL,
    NDFL,
    enabledNDFL,
  }: IPhoneAndCardComponent) => {
  const isPhone = title === 'Номер телефона получателя';
  const placeholder = isPhone ? '+7 XXX XXX-XX-XX' : 'XXXX XXXX XXXX XXXX';

  return (
    <div>
      <div className={'money-transfer-tabs__data-container'}>
        <SecondInput
          error={error}
          title={title}
          placeholder={placeholder}
          value={value}
          setValue={setValue}
          mask={mask}
          className={'money-transfer-tabs__phone-and-card'}
          errorBG={'#FF4B40'}
          errorColor={'#FFDCE7'}
          isMask={true}
        />
      </div>
      <SumComponent
        setErrorSum={setErrorSum}
        isPhone={isPhone}
        sum={sum}
        markup={markup}
        commission={commission}
        setSum={setSum}
        setMarkup={setMarkup}
        balance={balance}
        setNDFL={setNDFL}
        NDFL={NDFL}
        enabledNDFL={enabledNDFL}
      />
    </div>
  );
};

const MoneyTransferTabs = (
  {
    activeTab,
    setActiveTab,
    buttonInfo,
  }:{
    activeTab: number,
    setActiveTab: (data: number) => void,
    buttonInfo: string,
  }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const purse = useAppSelector(state => state.dashboard.purse);
  const user = useAppSelector(state => state.auth.user);
  const error = useAppSelector(state => state.payments.error);
  const data = useAppSelector(state => state.payments.data);
  const activeBankIndex = useAppSelector(state => state.payments.activeBankIndex);
  const bankList = useAppSelector(state => state.payments.bankList);
  const transactionReady = useAppSelector(state => state.payments.transactionReady);
  const recipientName = useAppSelector(state => state.payments.recipientData.recipientName);
  const recipientDataLoading = useAppSelector(state => state.payments.recipientData.recipientDataLoading);

  const [phone, setPhone] = useState('');
  const [cardNum, setCardNum] = useState('');
  const [sum, setSum] = useState('');
  const [errorSum, setErrorSum] = useState('');
  const [markup, setMarkup] = useState('');
  const [NDFL, setNDFL] = useState('');
  const [loading, setLoading] = useState(false);
  const [checkbox, setCheckbox] = useState(false);
  const [activeTabName, setActiveTabName] = useState('');

  const acceptStatusNDFL = 2;

  const maskPhone = ['+', '7', ' ', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];
  const maskCard = [
    /\d/, /\d/, /\d/, /\d/, ' ',
    /\d/, /\d/, /\d/, /\d/, ' ',
    /\d/, /\d/, /\d/, /\d/, ' ',
    /\d/, /\d/, /\d/, /\d/];

  const personalData = activeTabName === 'Банковская карта'
    ? `${parseInt(cardNum.replaceAll(' ', ''))}`.length < 16
    : `${phone.replaceAll('_', '')}`.length < 18;

  const maxSum = activeTabName === 'Телефон'
    ? parseInt(sum) <= MAX_SUM_PHONE
    : parseInt(sum) <= MAX_SUM_CARD;
  const minSum = activeTabName === 'Телефон'
    ? parseInt(sum) >= 1
    : parseInt(sum) >= 10;

  const enabledButton = (minSum && maxSum)
    && !personalData
    && !error
    && !errorSum
    && (transactionReady ? checkbox : true)
    && (purse?.is_taxable_payments ? (user?.tax != null ? user?.tax.status === acceptStatusNDFL : true) || !transactionReady : true);

  const enabledNDFL = user?.tax !== null
    ? purse?.is_taxable_payments && purse.tax_payer === 'participant'
    : false;

  const showBankTabs = typeof purse?.bank_commission === 'string';
  const showPhoneTab = typeof purse?.phone_commission === 'string';

  const tabs = [
    {
      name: 'СБП',
      hidden: false,
      component: <SBP
        sum={sum}
        markup={markup}
        setSum={setSum}
        setErrorSum={setErrorSum}
        setMarkup={setMarkup}
        mask={maskPhone}
        NDFL={NDFL}
        setNDFL={setNDFL}
        phone={phone}
        setPhone={setPhone}
        balance={purse?.balance}
        enabledNDFL={!!enabledNDFL}
        commission={typeof purse?.bank_commission === 'string' ? parseFloat(purse?.bank_commission) : 0}
      />
    },
    {
      name: 'Банковская карта',
      hidden: !showBankTabs,
      component: <PhoneAndCard
        title={'Номер карты'}
        mask={maskCard}
        sum={sum}
        markup={markup}
        setSum={setSum}
        setErrorSum={setErrorSum}
        setMarkup={setMarkup}
        NDFL={NDFL}
        setNDFL={setNDFL}
        value={cardNum}
        setValue={setCardNum}
        balance={purse?.balance}
        enabledNDFL={!!enabledNDFL}
        commission={typeof purse?.bank_commission === 'string' ? parseFloat(purse?.bank_commission) : 0}
        error={error}
      />
    },
    {
      name: 'Телефон',
      hidden: true,
      component: <PhoneAndCard
        title={'Номер телефона получателя'}
        mask={maskPhone}
        sum={sum}
        markup={markup}
        setSum={setSum}
        setErrorSum={setErrorSum}
        setMarkup={setMarkup}
        NDFL={NDFL}
        setNDFL={setNDFL}
        value={phone}
        setValue={setPhone}
        error={error}
        balance={purse?.balance}
        enabledNDFL={!!enabledNDFL}
        commission={typeof purse?.phone_commission === 'string' ? parseFloat(purse?.phone_commission) : 0}
      />
    },
  ];

  const click = () => {
    if (!enabledButton) {
      return;
    }
    if (!transactionReady) {
      dispatch(setTransactionReady(true));
    } else {
      if (purse?.id) {
        dispatch(createPayment({
          purse_id: purse?.id,
          sum: parseInt(sum),
          commission: markup ? parseInt(markup) : 0,
          ndfl: parseInt(NDFL),
          card: cardNum ? cardNum.replaceAll(' ', '') : undefined,
          phone: phone ? phone.replace(/[^\d+]/g, '') : undefined,
          type: 'card',
          bank_id: activeBankIndex !== null ?
            bankList[0].bank_id
            : undefined
        }));
        dispatch(setBankActiveIndex(null));
        dispatch(clearBankList());
        dispatch(setTransactionReady(false));
      }
    }
  };

  const cancel = () => {
    dispatch(setTransactionReady(false));
    dispatch(setBankActiveIndex(null));
    dispatch(clearBankList());
    setCheckbox(false);
  };

  const changeTab = (index: number) =>{
    dispatch(setTransactionReady(false));
    setPhone('');
    setCardNum('');
    setSum('');
    dispatch(setBankActiveIndex(null));
    setMarkup('');
    setNDFL('');
    setCheckbox(false);
    setActiveTab(index);
    dispatch(clearError());
    dispatch(clearBankList());
  };

  useEffect(() => {
    if (!error) {
      return;
    }
    if (error && error.includes('Неверный номер')) {
      dispatch(setTransactionReady(false));
    } else if (error.length) {
      dispatch(showPopup({
        props: {
          error: `${error}`,
          onClose: () => {dispatch(clearError());}
        },
        popup: 'transferOrderReject'
      }));
    } else {
      dispatch(hidePopup('transferOrderReject'));
    }
  }, [error]);

  useEffect(() => {
    if (data.code === 'ok') {
      history.push('/dashboard/prizes');
      dispatch(showPopup('moneyTransferSuccess'));
      dispatch(clearData());
    }
  }, [data]);

  useEffect(() => {
    dispatch(clearError());
  }, [phone, cardNum]);

  useEffect(() => {
    setActiveTabName(tabs[activeTab].name);
  }, [activeTab]);

  useEffect(() => {
    if (activeTabName === 'Телефон') {
      dispatch(showPopup('moneyTransferAlert'));
    }

  }, [activeTabName]);

  useEffect(() => {
    dispatch(clearError());

    return cancel();
  }, []);

  useEffect(() => {
    if (parseInt(sum) > 0 && enabledNDFL) {
      setNDFL(`${Math.round((parseInt(sum) / ndfl_value) - (parseInt(sum)))}`);
    }
  }, [sum]);

  return (
    <div className={'money-transfer-tabs'}>
      <div className={'money-transfer-tabs__tab-list'}>
        {tabs.map((item, index) => {
          const isActive = activeTab === index;

          if (item.hidden) {
            return;
          }

          return (
            <div
              key={item.name}
              onClick={() => changeTab(index)}
              className={`
                money-transfer-tabs__tab-item
                ${isActive ? 'money-transfer-tabs__tab-item_active' : null}
              `}>
              {item.name}
            </div>
          );
        })}
      </div>
      <div className={'money-transfer-tabs__tab-content'}>
        {!transactionReady
          ? tabs[activeTab].component
          : (
            <>
              <div className={'money-transfer-tabs__ready-transaction'}>
                <ul className={'money-transfer-tabs__ready-transaction-list'}>
                  <li>
                    Номер {phone ? 'телефона' : 'карты'}:
                    <span className={'money-transfer-tabs__transaction-info'}> {phone ? phone : cardNum}</span>
                  </li>

                  <li>
                    Сумма перевода: <span className={'money-transfer-tabs__transaction-info'}>{sum}</span>
                  </li>
                  {activeBankIndex !== null ? (
                    <li>
                      Банк получателя:
                      <span className={'money-transfer-tabs__transaction-info'}>
                        {
                          bankList.length > 1 && bankList[activeBankIndex] ? bankList[activeBankIndex].name : bankList[0].name
                        }
                      </span>
                    </li>
                  ) : null}
                  {
                    purse?.min_payment_commission && purse?.min_payment_commission > 0 ? (
                      <li>
                        Комиссия: <span className={'money-transfer-tabs__transaction-info'}>{markup} ₽</span>
                      </li>
                    ) : null
                  }
                  {NDFL ? (
                    <li>
                      НДФЛ: <span className={'money-transfer-tabs__transaction-info'}>{NDFL} ₽</span>
                    </li>
                  ) : null}
                </ul>
              </div>
              <div className={'money-transfer-tabs__transaction-sum'}>
                Сумма списания с баланса:
                <b>{parseInt(sum) + parseInt(NDFL ? NDFL : '0') + parseInt(markup ? markup : '0')} ₽</b>
              </div>
            </>
          )}
      </div>
      <div className={'money-transfer-tabs__checkbox'}>
        {transactionReady ? (
          <Checkbox checkbox={checkbox} onClick={() => setCheckbox(!checkbox)} />
        ) : null}
        <div className={'money-transfer-tabs__button-info'}>
          Продолжая ваши действия, вы подтверждаете корректность введенных данных и соглашаетесь с условиями документа
          <span
            onClick={() => {
              window.open('/Оферта физические лица.pdf', '_blank')?.focus();
            }}
            className={'money-transfer-tabs__link'}>
            &nbsp;Оферта для физических лиц
          </span>
        </div>
      </div>
      <Button
        loading={false}
        loaderColor={'#4661C4'}
        disabled={!activeTab
          ? (!enabledButton
            || (activeBankIndex === null))
          : !enabledButton}
        onClick={click}
        className={`money-transfer-tabs${transactionReady ? '__button-transaction-ready' : '__button-transaction'}`}>
        Отправить
      </Button>
      {
        transactionReady
          ? (
            <div onClick={cancel} className={'money-transfer-tabs__cancel-transaction'}>
              Изменить данные
            </div>
          ) : null
      }
    </div>
  );
};

export default MoneyTransferTabs;
