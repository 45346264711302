import {Dispatch, SetStateAction, useRef} from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import type { IStore, AppDispatch } from 'store/store';
import {showPopup} from '../store/components/PopupSlice';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<IStore> = useSelector;

export const useFocus = () => {
  const htmlElRef = useRef<any>(null);
  const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus();};

  return [ htmlElRef, setFocus ];
};

export const getSearchForOtherProject = (
  search: string,
  setState?: Dispatch<SetStateAction<boolean>>,
  dispatch?: any,
  openPopup?: any
) => {
  const searchArray =
    search.replace('?', '').split('=');

  if (searchArray[0] === 'auth' && searchArray[1] === 'open-popup' && openPopup === 'auth') {
    setState && setState(true);
    openPopup && dispatch(showPopup(openPopup));

    return true;
  } else if (searchArray[0] === 'auth' && searchArray[1] === 'open-auth' && openPopup === 'auth') {
    openPopup && dispatch(showPopup(openPopup));
  } else if (searchArray[0] === 'card-balance' && searchArray[1] === 'open-popup' && openPopup === 'checkCard') {
    openPopup && dispatch(showPopup(openPopup));
  } else {
    setState && setState(false);

    return false;
  }
};
