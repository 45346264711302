import React, {useState} from 'react';

import {useAppDispatch, useAppSelector} from 'utils/hooks';

import {changeCheckbox, deleteItemCart} from 'store/components/CartSlice';
import {GetIcon} from '../../../../../../../../store/enums/normalizedIcons';

import Counter from 'components/counter/Counter';
import WindowLoading from 'components/windowLoading/WindowLoading';

import Checkbox from '../checkbox/Checkbox';


import './ListCarts.scss';

const ListCarts= () => {
  const colors = useAppSelector(state => state.settings.colors);
  const domain = useAppSelector(state => state.settings.currentDomainName);
  const markup = useAppSelector(state => state.cart.total_markup);

  const dispatch = useAppDispatch();

  const cart = useAppSelector(state => state.cart.cart);

  const [error, setError] = useState(false);

  const visibleError = () => {
    setError(true);
    setTimeout(() => {
      setError(false);
    }, 4000);
  };
  const handleCheckbox = (id: number) => {
    dispatch(changeCheckbox(id));
  };

  const deleteItem = (id: number) => {
    dispatch(deleteItemCart({id}));
  };

  if (!cart) {
    return null;
  }

  return (
    <div className={'list-cart'}>
      <WindowLoading id={'cartLoading'} className={'list-cart__loading'} />
      <div className={'list-cart__container'}>
        <div className={'list-cart__table-head cart-head'}>
          <div className={'cart-head__label list-cart__label_name'}>Наименование</div>
          <div className={'cart-head__container'}>
            <div className={'cart-head cart-head__nominal'}>Номинал</div>
            {
              markup
                ? (
                  <div className={'cart-head cart-head__commission'}>Комиссия</div>
                )
                : null
            }
            <div className={'cart-head cart-head__count'}>Кол-во</div>
            <div className={'cart-head cart-head__sum'}>Итого</div>
            <div className={'cart-head cart-head__cross'}/>
          </div>
        </div>
        <div className={'cart-head__table-body'}>
          {
            cart?.map((item) => (
              <div key={item.id} className={'list-cart__item-container'}>
                <div className={'list-cart__item-head'}>
                  <div className={'list-cart__name-container'}>
                    <Checkbox
                      className={'list-cart__checkbox'}
                      checkbox={item.checkbox || false}
                      onClick={() => handleCheckbox(item.id)}
                    />
                    <div className={'list-cart__info'}>
                      <span
                        className={'list-cart__is-reserved'}>
                        {item.is_reserved ? 'Из наличия - в течение 1 часа' : `Под заказ - ${item.validity_period}`}
                      </span>
                      <span className={'list-cart__name'}>
                        <span
                          className={`
                          list-cart__name_black
                          ${item.name.length > 16 ? 'list-cart__name_hidden' : ''}
                        `}>
                          {item.name}
                        </span>
                      </span>
                      {/*<span className={'list-cart__time'}>{item.time}</span>*/}
                    </div>
                  </div>
                  <div className={'list-cart__icon-container_mobile'}>
                    <span onClick={() => deleteItem(item.id)} className={'list-cart__icon'}>
                      <GetIcon
                        iconName={'Cross'}
                        fill={colors[domain].crossIcon.mainIcon}
                        secondaryFill={colors[domain].crossIcon.secondIcon}
                        opacity={colors[domain].crossIcon.opacity}
                      />
                    </span>
                  </div>
                </div>
                <div key={item.id} className={'list-cart__item'}>
                  <div className={'list-cart__name-container_desktop'}>
                    <div className={'list-cart__name-container list-cart__name-container_desktop'}>
                      <Checkbox
                        checkbox={item.checkbox || false}
                        onClick={() => handleCheckbox(item.id)}
                        className={'list-cart__checkbox'}
                      />
                      <div className={'list-cart__info'}>
                        <span
                          className={'list-cart__is-reserved'}>
                          {item.is_reserved ? 'Из наличия - в течение 1 часа' : `Под заказ - ${item.validity_period}`}
                        </span>
                        <span className={'list-cart__name'}>
                          <span
                            className={`
                            list-cart__name_black
                            ${item.name.length > 16 ? 'list-cart__name_hidden' : ''}
                          `}>
                            {item.name}
                          </span>
                        </span>
                        {/*<span className={'list-cart__time'}>{item.time}</span>*/}
                      </div>
                    </div>
                  </div>
                  <div className={'main-info__container main-info'}>
                    <div className={'list-cart__price-container main-info__nominal-container'}>
                      <span className={'main-info__title'}>Номинал</span>
                      <span className={'main-info__price'}>{item.nominal} ₽</span>
                    </div>
                    {
                      markup
                        ? (
                          <div className={'list-cart__price-container main-info__nominal-container'}>
                            <span className={'main-info__title'}>Комиссия</span>
                            <span className={'main-info__price main-info__commission'}>{item.markup_nominal} ₽</span>
                          </div>
                        )
                        : null
                    }
                    <div className={'main-info__count'}>
                      <span className={'main-info__title main-info__title_text-center'}>Кол-во</span>
                      <Counter
                        error={visibleError}
                        item={item}
                        className={'main-info__counter'}
                        modeCounter={'cart'}
                      />
                    </div>
                    <div className={'main-info__total-container main-info__sum'}>
                      <span className={'main-info__title main-info__title_text-right'}>Итого</span>
                      <span className={'main-info__total'}>{item.total} ₽</span>
                    </div>
                    <div className={'main-info__icon-container'}>
                      <span onClick={() => deleteItem(item.id)} className={'list-cart__icon'}>
                        <GetIcon
                          iconName={'Cross'}
                          fill={colors[domain].crossIcon.mainIcon}
                          secondaryFill={colors[domain].crossIcon.secondIcon}
                          opacity={colors[domain].crossIcon.opacity}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
      <div className={'list-cart__bottom-holder'} />
      <div className={'list-cart__error'}>{error ? 'Недостаточно средств в кошельке' : ' '}</div>
    </div>
  );
};

export default ListCarts;
