import React, {useEffect, useRef} from 'react';
import {useAppDispatch, useAppSelector} from 'utils/hooks';

import env from 'env/env.json';

import {getNDFLAuthToken} from 'store/components/AuthSlice';

import './NDFLWidget.scss';

const NdflWidget = () => {
  const dispatch = useAppDispatch();
  const surveyURL = env.ndflURL;

  const ndflToken = useAppSelector(state => state.auth.user?.ndfl_auth_token);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    dispatch(getNDFLAuthToken());
  }, []);

  const sendMessageToIframe = (message: string) => {
    if (iframeRef.current) {
      const iframeWindow = iframeRef.current.contentWindow;
      iframeWindow?.postMessage({temporary_refresh_token: message}, '*');
    } else {
      console.error('Iframe not found.');
    }
  };

  useEffect(() => {
    if (ndflToken) {
      sendMessageToIframe(ndflToken);
    }
  }, [ndflToken]);

  return (
    <>
      {ndflToken ? (
        <iframe
          src={`${surveyURL}?temporary_token=${ndflToken}`}
          ref={iframeRef}
          className="ndfl-widget__iframe"
          frameBorder="0"
          allowFullScreen
        ></iframe>
      ) : null}
    </>
  );
};

export default NdflWidget;
